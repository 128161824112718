.button-loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3.8px solid;
  border-color: #dbdcef;
  border-right-color: rgba(19, 17, 17, 0);
  animation: spinner-d3wgkg 1s infinite linear;
  padding-right: 5px;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}
