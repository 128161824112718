.background-success {
    /* background-image: url(/src/assets/8709532.jpg); */
  /* background-size: cover; */
  /* background: #757575; */
  
}
#card {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* top: 110px; */
  /* width: 320px; */
  display: flex;
  /* display: block; */
  margin: auto;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  /* background: url(/src/assets/8709532.jpg); */
  /* background-color: #757575; */
}

#upper-side {
  padding: 2em;
  width: 220%;
  height: 400px;
  background-color: #8bc34a;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark {
  font-weight: lighter;
  fill: #fff;
  /* margin: -3.5em auto auto 20px; */
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2em;
  font-weight: 800;
  margin-top: 2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 2em 5em 2em;
 
  background: #fff;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: 2em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #8bc34a;
  color: #fff;
  margin: auto;
  padding: 0.8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
