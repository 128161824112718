.pricing-content {
  position: relative;
  padding: 5%;
  background: #101820; /* Black background for the entire section */
}

.pricing_design {
  position: relative;
  margin: 0px 15px;
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center the pricing boxes */
  gap: 30px; /* Add space between the boxes */
}

.pricing_design .single-pricing {
  background: linear-gradient(145deg, #1E90FF, #001F3F); /* Vibrant blue gradient */
  padding: 60px 40px;
  border-radius: 30px;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.3), 0 0 30px rgba(30, 144, 255, 0.5);
  position: relative;
  z-index: 1;
  overflow: hidden; /* Hide any overflow elements */
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
  color: #fff; /* Text color white for better contrast */
}

.pricing_design .single-pricing:hover {
  transform: translateY(-10px); /* Lift the box on hover */
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.4), 0 0 40px rgba(30, 144, 255, 0.7);
  /* Enhance shadow on hover */
}

.pricing_design .single-pricing:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 18px 18px 190px 18px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.price-head {
  text-align: center; /* Center-align the headings */
}

.price-head h2 {
  margin-bottom: 20px;
  font-size: 28px; /* Larger font size for headings */
  font-weight: 700; /* Bolder weight for emphasis */
  color: #fff; /* White color for headings */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Add subtle text shadow */
}

.price-head h1 {
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 40px; /* Larger font size for the price */
  color: #FFD700; /* Golden color for price */
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Intense shadow for price */
}

.price-head span {
  font-size: 20px; /* Font size for subtext */
  color: #fff;
}

.single-pricing ul {
  list-style: none;
  margin-top: 30px;
  padding: 0; /* Remove padding */
}

.single-pricing ul li {
  line-height: 36px;
  font-size: 15px; /* Slightly larger font size */
  position: relative; /* Position for icons */
  padding-left: 30px; /* Space for icon */
  color: #fff; /* White color for list items */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); /* Subtle shadow for text */
}

.single-pricing ul li:before {
  content: '✔';
  position: absolute;
  left: 0;
  background: #FFD700; /* Golden background for icon */
  color: #000; /* Black color for icon text */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 6px;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.pricing-price {
  text-align: center; /* Center-align the button */
}

.price_btn {
  background: linear-gradient(145deg, #1E90FF, #001F3F);
  padding: 15px 35px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
  border-radius: 30px; /* Rounded button */
  transition: background 0.3s ease, transform 0.3s ease;
  font-weight: 600; /* Bold text */
  text-transform: uppercase; /* Uppercase text for button */
  letter-spacing: 1px; /* Spacing between letters */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); /* Subtle text shadow */
}

.price_btn:hover {
  background: linear-gradient(145deg, #001F3F, #1E90FF);
  transform: scale(1.05); /* Scale the button on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Enhance shadow */
}

a {
  text-decoration: none;
  color: inherit; /* Inherit color from parent */
}

.section-title {
  margin-bottom: 60px;
  text-align: center; /* Center-align the section title */
  color: #fff; /* White color for the title */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow for title */
}

.section-title h2 {
  font-size: 45px;
  font-weight: 600;
  margin-top: 0;
  position: relative;
  text-transform: capitalize;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pricing_design {
    flex-direction: column; /* Stack the pricing boxes */
    gap: 20px;
  }

  .pricing_design .single-pricing {
    width: 100%; /* Full width for small screens */
    padding: 40px 20px; /* Adjust padding */
  }

  .price-head h2 {
    font-size: 24px;
  }

  .price-head h1 {
    font-size: 36px;
  }

  .price_btn {
    padding: 12px 28px; /* Adjust button padding */
    font-size: 14px; /* Adjust button font size */
  }
}
