/* General Styles */
.landing-page {
    /* font-family: 'Arial', sans-serif; */
    background: linear-gradient(to bottom right, #011347, #000000);
    color: #fff;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

/* Hero Section */
.hero-section {
    position: relative;
    padding: 100px 100px;
    text-align: center;
    margin-top: 50px;
    animation: fadeIn 2s ease-in-out;
}

.hero-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.2;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero-description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #2980b9;
}

.hero-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 8px;
    z-index: 1;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    overflow: hidden;
}

.hero-button:hover {
    background-color: #2980b9;
    transform: translateY(-3px);
}
.section-title{
    font-size: 2rem;
    font-weight: 800;
    color: #ecf0f1;
}
/* Features Section */
.features-section {
    padding: 80px 20px;
    text-align: center;
}

.features-container {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.feature-item {
    width: 30%;
    padding: 20px;
    background-color: #ecf0f1;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateY(-5px);
}

.feature-title {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: #3498db;

}

.feature-description {
    font-size: 1.2rem;
    color: #000;
}

.feature-image {
    width: 100%;
    border-radius: 8px;
}

/* Testimonials Section */
.testimonials-section {
    padding: 80px 20px;
    text-align: center;
}

.testimonials-container {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.testimonial-item {
    width: 40%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #34495e;
}

.testimonial-author {
    font-size: 1rem;
    font-weight: bold;
    color: #2c3e50;
}

/* How It Works Section */
.how-it-works-section {
    padding: 80px 20px;
    text-align: center;
}

.steps-container {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.step-item {
    width: 30%;
    padding: 20px;
    background-color: #3498db;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
}

.step-item:hover {
    transform: scale(1.05);
}

.step-number {
    font-size: 3rem;
    margin-bottom: 10px;
}

.step-description {
    font-size: 1.2rem;
    color: #fff;
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInBottom {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes flipIn {
    0% {
        opacity: 0;
        transform: rotateY(90deg);
    }
    100% {
        opacity: 1;
        transform: rotateY(0);
    }
}

.slide-in-bottom {
    animation: slideInBottom 1s ease;
}

.bounce-in {
    animation: bounceIn 1s ease;
}

.flip-in {
    animation: flipIn 1s ease;
}

.zoom-in {
    animation: zoomIn 1s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    .features-container, .steps-container, .testimonials-container {
        flex-direction: column;
    }

    .feature-item, .testimonial-item, .step-item {
        width: 100%;
        margin-bottom: 30px;
    }

    .hero-title {
        font-size: 2rem;
    }

    .hero-description {
        font-size: 1.2rem;
    }

    .section-title {
        font-size: 2rem;
    }
}
