/* 

  
  .dashboard-outer {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .upper-title-box {
    padding: 20px;
    background-color: #3498db;
    color: #fff;
    text-align: center;
  }
  
  .upper-title-box h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
  
  .upper-title-box .text {
    font-size: 16px;
    margin-top: 8px;
    color: #e1eaf3;
  }
  
  .tabs-box {
    padding: 20px;
  }
    */
    .widget-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      
      /* .widget-title h4 {
          font-size: 20px;
          font-weight: 600;
          color: #333;
        } */
      
      .chosen-outer {
        display: flex;
        gap: 10px;
      }
      .ant-tooltip {
        font-size: 14px; /* Customize the font size */
        background-color: #f0f0f0; /* Customize the background color */
        color: #000; /* Customize the text color */
      }
      
      .chosen-select {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        transition: border-color 0.3s;
      }
      
      .chosen-select:focus {
        border-color: #3498db;
      }
      
      .widget-content {
        padding: 20px;
        border-top: 1px solid #f0f0f0;
      }
      
      .text-center {
        text-align: center;
        color: #888;
        font-size: 16px;
      }
      
      .tabs-box .aplicants-upper-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
      }
      
      /* .aplicants-upper-bar h6 {
          font-size: 18px;
          font-weight: 500;
          color: #333;
        } */
      
      .aplicantion-status {
        display: flex;
        gap: 10px;
      }
      
      .tab-btn {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: #e8f0fe;
        color: #1a73e8;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      .tab-btn.active-btn {
        /* background-color: #1a73e8; */
        color: #fff;
      }
      
      .tab-btn:hover {
        background-color: #d2e3fc;
      }
      
      .candidate-block-three {
        display: flex;
        align-items: center;
        padding: 20px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        margin-bottom: 15px;
        background-color: #fff;
        transition: box-shadow 0.3s;
      }
      
      .candidate-block-three:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      
      .inner-box {
        display: flex;
        /* justify-content: space-evenly; */
        align-items: center;
        width: 100%;
      }
      
        /* .content {
        }
        */
      .content h4 a {
        font-size: 18px;
        color: #333;
        text-decoration: none;
        transition: color 0.3s;
      }
      
      .content h4 a:hover {
        color: #3498db;
      }
      
      .candidate-info {
        list-style: none;
        padding: 0;
        margin: 8px 0;
        font-size: 14px;
        color: #555;
      }
      
      .candidate-info li {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      
      .candidate-info li .icon {
        font-size: 16px;
        color: #3498db;
      }
      
      .post-tags {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 5px;
      }
      
      .post-tags li {
        background-color: #eef4ff;
        color: #3498db;
        padding: 3px 8px;
        border-radius: 4px;
        font-size: 12px;
        transition: background-color 0.3s;
      }
      
      .post-tags li a {
        color: inherit;
        text-decoration: none;
      }
      
      .post-tags li:hover {
        background-color: #d2e3fc;
      }
      /* Scrollbar styling */
      
      /* Works on Firefox */
      .scroll-black * {
          scrollbar-width: thin;
          scrollbar-color: #d2e3fc auto;
      }
      
      /* Works on Chrome, Edge, and Safari */
      .scroll-black::-webkit-scrollbar {
          width: 7px;
      }
      
      .scroll-black::-webkit-scrollbar-track {
          background: transparent;
      }
      
      .scroll-black::-webkit-scrollbar-thumb {
          background-color: #d2e3fc;
        border-radius: 50%;
      }
      
      /* End scrollbar styling*/
      
      .btn-box-one {
        max-width: 40%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
      }
      
      .btn-box-one .row {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      
      .btn-box-one .d-flex {
        /* display: flex; */
        gap: 10px;
        margin-left: 30px;
      }
      
      .btn-box-one .d-flex div {
        /* flex-direction: column; */
        flex-wrap: wrap;
        /* justify-content: space-evenly; */
        align-items: center;
        display: flex;
        gap: 20px;
      }
      .button-container {
        display: flex;
        /* flex-wrap: wrap; */
        gap: 8px; /* Space between buttons */
      }
      
      .button-container .ant-btn {
        flex: 1 1 calc(50% - 8px); /* 50% width minus the gap */
        box-sizing: border-box;
      }
      
      .button-container .ant-btn:nth-of-type(2n) {
        margin-right: 0; /* Remove margin on even items for correct alignment */
      }
      
      
      .btn-box-one button {
        border-radius: 4px;
        transition: background-color 0.3s, box-shadow 0.3s;
      }
      
      .btn-box-one button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }
      
      /* Typewriter Container */
      .typewriter-container {
        padding: 20px;
      }
      
      .ai-result {
        font-size: 16px;
        color: #333;
        line-height: 1.5;
        margin-bottom: 20px;
        overflow: hidden;
        white-space: nowrap;
      }
      
      /* Score Circle */
      .score-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      
      .score-text {
        font-size: 24px;
        font-weight: bold;
        color: #333;
      }
      
      /* Responsive Styles */
      @media (max-width: 992px) {
        .candidate-block-three {
          flex-direction: column;
          align-items: flex-start;
        }
      
        .inner-box {
          flex-direction: column;
        }
      
        .content {
          max-width: 100%;
        }
      
        .btn-box-one {
          max-width: 100%;
        }
      
        .btn-box-one .row {
          flex-direction: row;
          justify-content: space-between;
          gap: 5px;
        }
      }
      
      @media (max-width: 768px) {
        .widget-title {
          flex-direction: column;
          align-items: flex-start;
        }
      
        .aplicants-upper-bar {
          flex-direction: column;
          align-items: flex-start;
        }
      
        .tabs-box {
          padding: 10px;
        }
      
        .candidate-info {
          font-size: 12px;
        }
      
        .post-tags li {
          font-size: 11px;
        }
      
        .btn-box-one .d-flex {
          flex-direction: column;
          gap: 5px;
        }
      }
      
      @media (max-width: 480px) {
        .upper-title-box h3 {
          font-size: 20px;
        }
      
        .upper-title-box .text {
          font-size: 14px;
        }
      
        .aplicants-upper-bar h6 {
          font-size: 16px;
        }
      
        .tab-btn {
          font-size: 12px;
        }
      
        .candidate-info {
          font-size: 11px;
        }
      
        .btn-box-one button {
          font-size: 12px;
          padding: 5px 10px;
        }
      
        .score-circle {
          width: 80px;
          height: 80px;
        }
      
        .score-text {
          font-size: 20px;
        }
      }
      