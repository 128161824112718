.instructions-modal-one {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-one {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
    overflow: hidden;
    animation: fadeIn 0.3s ease-out;
  }
  
  .modal-header-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #007bff;
    color: #fff;
  }
  
  .modal-header-one h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .modal-body-one {
    padding: 20px;
    font-size: 1em;
  }
  
  .modal-body-one ul {
    list-style: disc;
    padding-left: 20px;
  }

  .modal-body-one li {
    list-style: disc;
    padding-left: 20px;
    color: black;
  }
  
  .modal-footer-one {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: #f1f1f1;
  }
  
  .agree-button, .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 1em;
  }
  
  .agree-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #ccc;
  }
  
  .agree-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button:hover {
    background-color: #999;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  