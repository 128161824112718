/* FitmentAnalysisForm.css */
.fitment-analysis-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100vh; /* Full viewport height */
    background: #f0f0f0; /* Background color, change as needed */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.form-image,
.form-container {
    flex: 1; /* Each side takes up equal space */
    max-width: 50%; /* Ensures each side covers half the width */
    box-sizing: border-box; /* Includes padding and border in the width calculation */
    position: relative; /* Position relative for absolute positioning of animations */
}

.form-image img {
    width: 100%; /* Make image fill its container */
    height: 100%; /* Make image cover the container's height */
    object-fit: cover; /* Ensures image covers the area without distortion */
    transition: transform 0.5s ease; /* Smooth zoom effect on hover */
}

.form-image:hover img {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px ;
    background: url('../../assets/blue-black-background-with-blue-light-corner.jpg') no-repeat center center / cover; /* Add background image */
    /* border-radius: 8px; Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for a 3D effect */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.form-container:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Lighten background on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}


.form-container h2 {
    /* margin-bottom: 10px; */
    font-size: 1.5em; /* Larger heading size */
    color: #fff; /* Darker color for better readability */
    transition: color 0.3s ease; /* Smooth color transition */
    /* font-size: 20px; */
    font-weight: 800;
    text-align: center;
}

.form-container h2:hover {
    color: #007bff; /* Change color on hover */
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    opacity: 0; /* Start with form hidden */
    animation: fadeIn 1s forwards; /* Animation for fade-in effect */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

label {
    /* font-weight: bold; */
    font-size: 1.0em; /* Slightly larger labels */
    /* color: #fff; */
}

input[type="text"],
textarea,
input[type="file"] {
    width: 100%;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

input[type="text"]:focus,
textarea:focus,
input[type="file"]:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
}

textarea {
    resize: vertical;
}

/* button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; 

button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
} */

/* button:active {
    transform: translateY(1px);} */

/* Responsive Design */
@media (max-width: 768px) {
    .fitment-analysis-form {
        flex-direction: column;
    }

    .form-image,
    .form-container {
        max-width: 100%; /* Full width on small screens */
    }

    .form-image {
        margin-bottom: 20px;
    }
}
